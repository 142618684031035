// 注文手続き共通画面

<template lang="pug">
.order-common

  header.p-3.bg-teal-300.text-teal-800.flex.justify-center
    h1.flex.items-center
      fa-icon(icon='book-open', size='lg')
      .ml-3 おもいでBook作成サイト

  .container.px-3.py-10.mx-auto
    router-view
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="sass" scoped>
.order-common
  min-height: 100vh
  background: linear-gradient(#f0fcfd, #fff)
</style>
